import React from 'react';

const Loading = ({ heading }) => (
  <div className="main-content">
    <h3>{heading}</h3>
    <div className="row small-12 small-centered columns">
      <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
    </div>
  </div>
);

export default Loading;

