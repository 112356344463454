import React from 'react';
import CollectShipAddressConfig from './Configs/CollectShipAddressConfig';
import CalculateCheckTaxesConfig from './Configs/CalculateCheckTaxesConfig';
import { useShopTypeById } from '../../redux/hooks/shops';


const ShopCheckoutConfig = ({ shop_id }: { shop_id: string }) => {
  const shopType = useShopTypeById(shop_id);

  return (
    <div className="row">
      {shopType === 'POPUP_SHOP' && <CollectShipAddressConfig shop_id={shop_id} />}
      {shopType === 'POPUP_SHOP' && <CalculateCheckTaxesConfig shop_id={shop_id} />}
      {/* todo: implement and add other checkout config/section components */}
    </div>
  );
};

export default ShopCheckoutConfig;
