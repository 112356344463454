import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatMoney, parseMysqlDate } from '../../utils';

import { createAssignToClientPopup, createJoinOrdersPopup } from '../../actions/popup';
import { loadShopOrders } from '../../actions/shop';

import ContentLoading from '../ContentLoading';
import Select from '../Select';
import Address from '../Address';

class Staging extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sort: 'date_created',
      loading: true,
      selected: []
    };
  }

  UNSAFE_componentWillMount() {
    this.props.loadShopOrders().then(
      () => this.setState({ loading: false })
    );
  }

  renderNoClient() {
    const { sort } = this.state;
    const { shop, onAssignToClient } = this.props;

    const sort_options = [
      { key: 'date_created', value: 'Sort by date created', sort: (a, b) => Date.parse(a.date_created) - Date.parse(b.date_created) },
      { key: 'client_contact', value: 'Sort by client contact', sort: (a, b) => {
        const a1 = (a.billing_contact_first_name + a.billing_contact_last_name);
        const b1 = (b.billing_contact_first_name + b.billing_contact_last_name);
        if (a1 < b1) {
          return -1;
        } else if (a1 > b1) {
          return 1;
        }
        return 0;
      } },
      { key: 'total_subtotal', value: 'Sort by order value', sort: (a, b) => a.total_subtotal - b.total_subtotal }
    ];
    const orders = shop.sales_orders.filter(so => !so.client_id).sort(sort_options.filter(s => s.key === sort)[0].sort);

    const handleClickAssign = order => e => {
      e.preventDefault();
      onAssignToClient(order);
    };

    return (
      <div className="main-content">
        <div className="row">
          <Select className="small-12 medium-4 columns" options={sort_options} value={sort} change={sort => this.setState({ sort })} />
        </div>
        <table>
          <thead>
            <tr>
              <th>Created</th>
              <th>Customer Name</th>
              <th>Company Name</th>
              <th>Email</th>
              <th>Address</th>
              <th>Value</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {orders.length ?
              orders.map(o =>
                <tr key={o.order_id}>
                  <td>{parseMysqlDate(o.date_created)}</td>
                  <td>{o.billing_contact_first_name} {o.billing_contact_last_name}</td>
                  <td>{o.billing_address_company}</td>
                  <td>{o.billing_contact_email}</td>
                  <td>
                    <Address
                      address_line_1={o.billing_address_line_1}
                      address_line_2={o.billing_address_line_2}
                      address_city={o.billing_address_city}
                      address_state={o.billing_address_state}
                      address_postal={o.billing_address_postal}
                      address_country={o.billing_address_country}
                    />
                  </td>
                  <td>${formatMoney(o.total_subtotal)}</td>
                  <td><a className="button" onClick={handleClickAssign(o)}>Assign to Client</a></td>
                </tr>) : <tr><td colSpan="6" style={{ textAlign: 'center' }}>No checkouts yet</td></tr>}
          </tbody>
        </table>
      </div>
    );
  }

  renderAggregates() {
    const { selected, sort } = this.state;
    const { shop, onJoinOrders } = this.props;

    const sort_options = [
      { key: 'date_created', value: 'Sort by date created', sort: (a, b) => Date.parse(a.date_created) - Date.parse(b.date_created) },
      { key: 'checked_out_by', value: 'Sort by customer', sort: (a, b) => {
        const a1 = a.checked_out_by;
        const b1 = b.checked_out_by;
        if (a1 < b1) {
          return -1;
        } else if (a1 > b1) {
          return 1;
        }
        return 0;
      } },
      { key: 'total_subtotal', value: 'Sort by order value', sort: (a, b) => a.total_subtotal - b.total_subtotal }
    ];

    const orders = shop.partials.sort(sort_options.filter(s => s.key === sort)[0].sort);

    const handleClickOrder = order_id => e => {
      if (selected.includes(order_id)) {
        this.setState({ selected: selected.filter(id => id !== order_id) });
      } else {
        this.setState({ selected: selected.concat(order_id) });
      }
    };

    const handleSelectAll = e => {
      if (selected.length === orders.length) {
        this.setState({ selected: [] });
      } else {
        this.setState({ selected: orders.map(o => o.order_id) });
      }
    };

    return (
      <div className="main-content">
        <div className="row">
          <Select className="small-12 medium-4 columns" options={sort_options} value={sort} change={sort => this.setState({ sort })} />
          <a className="button" style={{ float: 'right' }} onClick={() => onJoinOrders(selected)} disabled={!selected.length}>Create Project</a>
        </div>
        <table>
          <thead>
            <tr>
              <th>{!!orders.length && <input type="checkbox" checked={selected.length === orders.length} onClick={handleSelectAll} />}</th>
              <th>Created</th>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {orders.length ?
              orders.map(o =>
                <tr key={o.order_id}>
                  <td><input type="checkbox" checked={selected.includes(o.order_id)} onClick={handleClickOrder(o.order_id)} /></td>
                  <td>{parseMysqlDate(o.date_created)}</td>
                  <td>{o.checked_out_by}</td>
                  <td>{o.checked_out_email}</td>
                  <td>${formatMoney(o.total_subtotal)}</td>
                </tr>) : <tr><td colSpan="6" style={{ textAlign: 'center' }}>No checkouts yet</td></tr>}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const { shop } = this.props;

    if (loading) {
      return <ContentLoading />;
    }

    if (shop.aggregate == 1) {
      return this.renderAggregates();
    }else {
      return this.renderNoClient();
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadShopOrders: () => dispatch(loadShopOrders(ownProps.shop.shop_id)),
  onJoinOrders: order_ids => dispatch(createJoinOrdersPopup(ownProps.shop.shop_id, ownProps.shop.client_id, order_ids)),
  onAssignToClient: order => dispatch(createAssignToClientPopup(order))
});

export default connect(null, mapDispatchToProps)(Staging);
