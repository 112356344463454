import React from "react";
import { SwitchStyled, Typography } from "@commonsku/styles";
import { isAvalaraShop } from "../../../helpers/order";
import { useShopById, useUpdateShopById } from "../../../redux/hooks/shops";
import { getShopOptions } from "../../../helpers/shop";

const AggregateIgnoreTaxOnShippingConfig = ({ shop_id }: { shop_id: string }) => {
  const shop = useShopById(shop_id);
  const options = getShopOptions(shop?.shop_options);
  const onUpdateShop = useUpdateShopById(shop_id);
  const isAvalara = isAvalaraShop(shop);

  if (!shop.default_tax_id && !options?.use_zip2tax_default) {
    return <>{null}</>;
  }

  //boolean value is reversed in db
  const value = +shop.ignore_tax_on_shipping === 0 ? 1 : 0;
  return (
    <div className={["small-12", isAvalara ? "medium-3" : "medium-12", "columns"].filter(Boolean).join(' ')} style={{ marginBottom: '1rem' }}>
      <div>
        <Typography.Text bold fontSize={'16px'} style={{ marginBottom: '5px', ...(isAvalara ? { marginTop: '17px' } : {}) }}>
          Tax on Shipping
          {isAvalara ? null : <Typography.Span
            fontSize={'16px'}
            style={{ fontWeight: 'normal', paddingLeft: '5px' }}
          >Charge taxes on the Shipping cost at checkout</Typography.Span>}
        </Typography.Text>
        <div>
          {isAvalara ?
            <div>Calculated by Avalara</div> :
            <SwitchStyled
              size="medium"
              selected={value === 1}
              onClick={() => onUpdateShop(
                'ignore_tax_on_shipping',
                shop.ignore_tax_on_shipping,
                +shop.ignore_tax_on_shipping === 0 ? 1 : 0
              )}
              data-testid="ignore_tax_on_shipping_toggle"
            />
          }
        </div>
      </div>
    </div>
  );
};

export default AggregateIgnoreTaxOnShippingConfig;
