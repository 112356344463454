import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getInventoryItemsByInventoryId } from '../../selectors';

import { Button, LabeledSelect, Table, THead, TR, TH, TD, TBody } from '@commonsku/styles';
import DateInput, { CSKUInput } from '../DateInput';

import { createDownload, formatMoney, oauth } from '../../utils';

const ParameterSummary = ({ inventoryItem, startDate, endDate }) => {
  if (!inventoryItem && !startDate && !endDate) {
    return <span>All Orders</span>;
  }
  return (
    <>
      <span>Orders </span>
      {!!startDate && <span>From {startDate} </span>}
      {!!endDate && <span>To {endDate} </span>}
      {!!inventoryItem ? <span>For {inventoryItem}</span> : <span>For all products</span>}
    </>
  );
};

const InventoryOrders = ({ inventory }) => {
  const orders = [];
  const [inventoryItemId, setInventoryItemId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const inventoryItems = useSelector(state => getInventoryItemsByInventoryId(state, inventory));
  const productOptions = inventoryItems.map(ii => ({
    key: ii.inventory_item_id,
    value: `${ii.warehouse_code} (${ii.item_name})`
  }));
  const itemMap = inventoryItems.reduce((o, i) => ({ ...o, [i.inventory_item_id]: i}), {});

  const [allProjects, setAllProjects] = useState([]);
  const fetchProjects = () => {
    const data = { inventory_id: inventory.inventory_id };
    if (!!inventoryItemId) {
      data.inventory_item_id = inventoryItemId;
    }
    if (!!startDate) {
      data.start_date = startDate;
    }
    if (!!endDate) {
      data.end_date = endDate;
    }
    oauth('GET', 'job', data).then(
      ({ json }) => setAllProjects(json.jobs)
    );
  };

  useEffect(fetchProjects, []);
  const projects = allProjects.filter(
    p => !inventoryItemId || p.inventory_item_ids.includes(inventoryItemId)
  ).filter(
    p => !startDate || Date.parse(p.date_created) >= Date.parse(startDate + ' 00:00:00')
  ).filter(
    p => !endDate || Date.parse(p.date_created) <= Date.parse(endDate + ' 23:59:59')
  );

  const exportOrders = () => {
    const makeCSVSafe = value => {
      let finalValue = value === null ? '' : value.toString();
      finalValue = finalValue.replace(/"/g, '""');
      if (finalValue.search(/("|,|\n)/g) > 0) {
        finalValue = '"' + finalValue + '"';
      }
      return finalValue;
    };
    const content = 'data:text/csv;charset=utf-8,' + encodeURIComponent([
      ['Date', 'Project', 'Amount', 'PO Status'].join(',')
    ].concat(projects.map(p => [
      makeCSVSafe(p.date_created),
      makeCSVSafe(`#${p.job_number}: ${p.job_name}`),
      makeCSVSafe(`${p.currency_symbol}${formatMoney(p.total_subtotal)}`),
      makeCSVSafe(p.purchase_order_status)
    ].join(','))).join("\n"));
    createDownload(content, 'inventory-orders.csv');
  };

  return (
    <div style={{ marginTop: '1rem', marginRight: '1rem' }}>
      <LabeledSelect
          label="Inventory Product"
          options={productOptions}
          isClearable
          value={productOptions.filter(({ key }) => key === inventoryItemId)}
          getOptionLabel={({ value }) => value}
          getOptionValue={({ key }) => key}
          onChange={(value) => setInventoryItemId(!!value ? value.key : null)}
          containerStyles={{ marginBottom: '12px !important' }}
      />
      <div style={{ display: 'inline-block', width: '50%' }}>
        <DateInput
          customInput={<CSKUInput />}
          showMonthDropdown
          showYearDropdown
          placeholder="Start Date"
          value={startDate}
          onChange={date => setStartDate(date)}
        />
      </div>
      <div style={{ display: 'inline-block', width: '50%', paddingLeft: '0.5rem' }}>
        <DateInput
          customInput={<CSKUInput />}
          showMonthDropdown
          showYearDropdown
          placeholder="End Date"
          value={endDate}
          onChange={date => setEndDate(date)}
        />
      </div>
      <Table>
	  <THead>
          <TR>
	      <TH colSpan={3}>
              <ParameterSummary startDate={startDate} endDate={endDate} inventoryItem={productOptions.filter(o => o.key === inventoryItemId).map(ii => ii.value)[0]} />
	      </TH>
            <TH>
              <Button tiny style={{ float: 'right', marginRight: '0.5rem' }} onClick={exportOrders}>Export</Button>
            </TH>
	  </TR>
	</THead>
        <THead>
          <TR>
	    <TH>Date</TH>
            <TH>Project</TH>
            <TH>Total</TH>
            <TH>PO Status</TH>
          </TR>
        </THead>
        <TBody>
          {projects.length === 0 ?
            <TR colSpan={4}>
              <TD>No orders yet</TD>
            </TR> :
            projects.map(
              p => <TR key={p.job_id}>
                <TD>{p.date_created}</TD>
                <TD><a href={`/project/${p.job_number}`} target="_blank">#{p.job_number}: {p.job_name}</a></TD>
                <TD>{p.currency_symbol}{formatMoney(p.total_subtotal)}</TD>
                <TD>{p.purchase_order_status}</TD>
              </TR>
            )}
        </TBody>
      </Table>
    </div>
  );
};

export default InventoryOrders;
