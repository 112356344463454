import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Text } from '@commonsku/styles';

import { getIdentityUtils } from '../../utils';
import { useSelector } from 'react-redux';

const activeClassName = ({ isActive }) => isActive ? "active" : "";

const Menu = ({ shop, identity }) => {
  const { hasCapabilities, hasUserFlags } = getIdentityUtils(identity);
  const temp = useSelector((state) => state.temp);
  const onClick = e => {
    document.body.scrollTop = 0;
    const export_tasks = Object.keys(temp).filter(k => k.slice(0, 6) === 'export');
    if (export_tasks.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm('Export task is still running. Are you sure you want leave before getting your export file?')) {
        e.preventDefault();
      }
    }
  };
  const v2 = hasCapabilities('COMPANY-TYPE-DISTRIBUTOR');

  return (
    <>
      {v2 ? <div className="sub-right-menu-top">
        <Text as="p"className="sub-right-menu-top-title">Shop</Text>
      </div> : null}
      <ul className="vertical menu">
        <li className="show-for-small-only">
          <a href={hasUserFlags(['DASHBOARD-HOME']) ? '/dashboard.php' : '/home.php'}>Home</a>
        </li>
        <li>
          <NavLink end to={`/shop/${shop.shop_number}`} onClick={onClick} className={activeClassName}>Settings</NavLink>
        </li>
        <li>
          <NavLink end to={`/shop/${shop.shop_number}/products`} onClick={onClick} className={activeClassName}>Products</NavLink>
        </li>
        {!!shop.inventory_id && (hasCapabilities('MANAGE-INVENTORY') ? <li>
          <NavLink end to={`/shop/${shop.shop_number}/inventory`} onClick={onClick} className={activeClassName}>Inventory</NavLink>
        </li> :
        <li><a className="disabled">Inventory</a></li>)}
        <li>
          <NavLink end to={`/shop/${shop.shop_number}/storefront`} onClick={onClick} className={activeClassName}>Design</NavLink>
        </li>
        <li>
          <NavLink end to={`/shop/${shop.shop_number}/orders`} onClick={onClick} className={activeClassName}>Orders</NavLink>
        </li>
      </ul>
    </>
  );
};

export default Menu;
