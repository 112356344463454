import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createLoadOrder } from '../../actions/order';
import { createUpdateDisplayItemSummaryView, createUpdateDisplayItemSummaryTab } from '../../actions/display';
import { loadInventory } from '../../actions/inventory';

import { getOrderByNumber, getInventoryByShopId } from '../../selectors';

import ItemList from '../ItemList';

import { window } from '../../global';

class Products extends Component {

  UNSAFE_componentWillMount() {
    const { order: { loaded, order_id }, loadOrder, shop, inventory, loadInventory } = this.props;
    if (!loaded) {
      loadOrder(order_id);
    }
    if (!!shop.inventory_id && !inventory) {
      loadInventory();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { order: { loaded, order_id }, loadOrder, shop, inventory, loadInventory } = nextProps;
    if (!loaded && order_id !== this.props.order.order_id) {
      loadOrder(order_id);
    }
  }

  renderLoading() {
    return (
      <div className="main-content">
        <h3>Products</h3>
        <div className="row small-12 small-centered columns">
          <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
        </div>
      </div>
    );
  }

  render() {
    const { order, view, tab, onUpdateItemSummaryView, onUpdateItemSummaryTab } = this.props;

    if (!order.loaded) {
      return this.renderLoading();
    }

    const handleClickSummaryView = view => e => {
      e.preventDefault();
      onUpdateItemSummaryView(order.order_id, view);
    };

    const handleClickSummaryTab = tab => e => {
      e.preventDefault();
      onUpdateItemSummaryTab(order.order_id, tab);
    };

    return (
      <div className="main-content">
        <div className="row">
          <div className="small-12 columns">
            <ul className="switches">
            {'products' === tab &&
              <li>
                <div className="button-group compact-detailed-switch">
                  <a onClick={handleClickSummaryView('detailed')} className={'button' + ('detailed' === view ? ' active' : '')}>Detailed</a>
                  <a onClick={handleClickSummaryView('grid')} className={'button' + ('grid' === view ? ' active' : '')}>Grid</a>
                </div>
              </li>
            }
            </ul>
            <ul className="tabs" id="order-tabs">
              <li className={'tabs-title' + ('products' === tab ? ' is-active' : '')}>
                <a onClick={handleClickSummaryTab('products')} aria-selected={'products' === tab}>Products</a>
              </li>
              <li className={'tabs-title' + ('artwork' === tab ? ' is-active' : '')}>
                <a onClick={handleClickSummaryTab('artwork')} aria-selected={'artwork' === tab}>Artwork</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <ItemList
              key={order.order_id}
              location={window.location}
              order_id={order.order_id}
              order_type={order.order_type}
              items={order.items}
              display={'artwork' === tab ? 'artwork' : view }
              locked={1 === order.locked}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = getOrderByNumber(state, { form_number: ownProps.shop.shop_number, order_type: 'SHOP' });
  const inventory = getInventoryByShopId(state, ownProps.shop);
  const display = {
    item_summary_view: 'detailed',
    item_summary_tab: 'products',
    ...state.display.orders[order.order_id]
  };
  return {
    order,
    inventory,
    view: display.item_summary_view,
    tab: display.item_summary_tab
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadOrder: order_id => dispatch(createLoadOrder(order_id)),
  loadInventory: () => dispatch(loadInventory(ownProps.shop.inventory_id)),
  onUpdateItemSummaryView: (order_id, view) => dispatch(createUpdateDisplayItemSummaryView(order_id, view)),
  onUpdateItemSummaryTab: (order_id, tab) => dispatch(createUpdateDisplayItemSummaryTab(order_id, tab))
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
