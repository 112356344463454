import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderByNumber, getInventoryByShopId } from '../../selectors';
import { getUserContactOptions } from '../../selectors/dropdowns';
import { updateInventory, loadInventory } from '../../actions/inventory';
import { createLoadOrder } from '../../actions/order';

import { Label, LabeledCheckbox, LabeledInput, LabeledRadio, LabeledSelect } from '@commonsku/styles';

import Loading from './Loading';
import Warehouse from './InventoryWarehouse';

import { getIdentityUtils } from '../../utils';

const isInventoryValid = inventory => !!inventory &&
  !!inventory.inventory_name &&
  !!inventory.warehouse &&
  !!inventory.warehouse.warehouse_name &&
  !!inventory.warehouse.division_id &&
  !!inventory.warehouse.shipping_address_id &&
  !!inventory.warehouse.contact_id;

const InventoryEnabledHeader = ({ inventory }) => {
  return (
    <div style={{ float: 'right', marginRight: '1rem', fontSize: 'initial', textAlign: 'right' }}>
      {isInventoryValid(inventory) ?
        <span style={{ lineHeight: '2.5rem', color: '#00d374' }}>Enabled</span> :
        <>
          <span style={{ color: '#BF3F69' }}>Not Enabled</span>
          <span style={{ fontWeight: 'normal' }}>Fill out all the fields to enable</span>
        </>}
    </div>
  );
};

export const InventoryConfigureLoader = ({ shop, setHeader }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => getOrderByNumber(state, shop));
  const inventory = useSelector((state) => getInventoryByShopId(state, shop));

  useEffect(() => {
    if (!order.loaded) {
      dispatch(createLoadOrder(order.order_id));
    }
  }, [order.order_id]);

  useEffect(() => {
    if (!!shop.inventory_id) {
      if (!inventory) {
        dispatch(loadInventory(shop.inventory_id));
      } else {
        setHeader(<InventoryEnabledHeader inventory={inventory} />);
      }
    }
  }, []);

  useEffect(() => {
    if (!!order.loaded && !!inventory) {
      setHeader(<InventoryEnabledHeader inventory={inventory} />);
    }
  }, [inventory]);

  if (!order.loaded || (!inventory && !!shop.inventory_id)) {
    return (
      <div className="row" style={{ marginTop: '2rem' }}>
        <div className="small-12 columns">
          <Loading />
        </div>
      </div>
    );
  }
  return <InventoryConfigure inventory={inventory} />;
};

const MAX_INVENTORY_NAME_LENGTH = 5;

const InventoryConfigure = ({ inventory }) => {
  const dispatch = useDispatch();
  const contactOptions = useSelector((state) => getUserContactOptions(state));
  const hasCapabilities = useSelector((state) => getIdentityUtils(state.identity).hasCapabilities);
  const companyName = useSelector((state) => state.identity.company_name);
  const clientName = useSelector((state) => state.entities.clients[state.entities.shops[inventory.shop_id].client_id]);
  const [inventoryName, setInventoryName] = useState(inventory.inventory_name);
  const [warehouseName, setWarehouseName] = useState(inventory.warehouse.warehouse_name);

  const handleChangeInventoryName = e => {
    if (e.target.value.length <= MAX_INVENTORY_NAME_LENGTH) {
      setInventoryName(e.target.value);
    }
  };

  return (
    <div className="row" style={{ marginTop: '2rem' }}>
      <div className="small-12 medium-6 columns">
        <LabeledInput
          label="Inventory program number (maximum 5 characters)"
	  style={{ maxWidth: '10rem' }}
          value={inventoryName}
	  disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={handleChangeInventoryName}
          onBlur={() => dispatch(updateInventory(inventory.inventory_id, 'inventory_name', inventoryName))}
        />
      </div>
      <div className="small-12 medium-6 columns" style={{ paddingTop: '2rem', textAlign: 'right' }}>
        <LabeledCheckbox
          label="Show inventory on shop"
          checked={1 == inventory.show_on_shop}
	  disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={() => dispatch(updateInventory(inventory.inventory_id, 'show_on_shop', 1 == inventory.show_on_shop ? 0 : 1))}
        />
      </div>
      {/*<div className="small-12 medium-5 columns">
        <Label>Who owns the inventory?</Label>
        <LabeledRadio
          label={companyName}
          checked={'DISTRIBUTOR' === inventory.owner_type}
          disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={() => dispatch(updateInventory(inventory.inventory_id, 'owner_type', 'DISTRIBUTOR'))}
        />
        <LabeledRadio
          label={clientName}
          checked={'CLIENT' === inventory.owner_type}
          disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={() => dispatch(updateInventory(inventory.inventory_id, 'owner_type', 'CLIENT'))}
        />
      </div> */}
      <div className="small-12 columns">
        <LabeledSelect label="Low stock notification contacts"
          options={contactOptions}
          isMulti
          isClearable={false}
          value={contactOptions.filter(({ key }) => inventory.contacts.includes(key))}
          getOptionLabel={({ value }) => value}
          getOptionValue={({ key }) => key}
          disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={(values) => dispatch(updateInventory(inventory.inventory_id, 'contacts', (values || []).map(c => c.key)))}
          parentStyle={{ marginBottom: 9, }}
        />
      </div>
      <Warehouse warehouse={inventory.warehouse} />
    </div>
  );
};

export default InventoryConfigure;
