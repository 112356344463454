import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createLoadOrder } from '../../actions/order';
import { enableInventory, loadInventory } from '../../actions/inventory';

import { getOrderByNumber, getInventoryByShopId } from '../../selectors';

import { Tabs, Toggle, ToggleLink } from '@commonsku/styles';

import Loading from './Loading';
import InventoryProducts from './InventoryProducts';
import InventoryActivity from './InventoryActivity';
import InventoryOrders from './InventoryOrders';

const Inventory = ({ shop }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => getOrderByNumber(state, shop));
  const inventory = useSelector((state) => getInventoryByShopId(state, shop));

  useEffect(() => {
    if (!order.loaded) {
      dispatch(createLoadOrder(order.order_id));
    }
  }, [order.order_id]);

  useEffect(() => {
    if (!inventory && !!shop.inventory_id) {
      dispatch(loadInventory(shop.inventory_id));
    }
  }, []);

  if (!order.loaded || (!inventory && !!shop.inventory_id)) {
    return <Loading heading="Inventory" />;
  }

  return (
    <div className="main-content">
      <h3>Inventory</h3>
      <Tabs tabs={[{
        label: 'Inventory Products',
        content: <InventoryProducts inventory={inventory} order_id={order.order_id} />
      },
      {
        label: 'Inventory Orders',
        content: <InventoryOrders inventory={inventory} />
      },
      {
        label: 'Inventory Activity',
        content: <InventoryActivity inventory={inventory} />
      }]} />
    </div>
  );
};

export default Inventory;
