import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Button, Input, Table, THead, TR, TH, TD, TBody, Toggle, ToggleLink } from '@commonsku/styles';

import { createSelectProductsPopup } from '../../actions/popup.js';
import {
  UPDATE_INVENTORY_ITEM_FAILURE,
  UPDATE_INVENTORY_ITEM_SUCCESS,
  loadInventory,
  deleteInventoryItem,
  updateInventoryItem
} from '../../actions/inventory.js';

import { formatMoney } from '../../utils';

import { getInventoryItemsByInventoryId, getShopById } from '../../selectors';

const ItemProductStock = ({ inventory, item, onEdit, onDelete, onUpdate, editing }) => {
  const [available, setAvailable] = useState(item.available);
  const [reorderWarningLevel, setReorderWarningLevel] = useState(item.reorder_warning_level || '');
  const [price, setPrice] = useState(formatMoney(item.price));
  const [warehouseCode, setWarehouseCode] = useState(item.warehouse_code);
  const [initialAvailable, setInitialAvailable] = useState(item.available);
  const [initialReorderWarningLevel, setInitialReorderWarningLevel] = useState(item.reorder_warning_level || '');
  const [initialPrice, setInitialPrice] = useState(item.price);
  const [initialWarehouseCode, setInitialWarehouseCode] = useState(item.warehouse_code);
  const onClickEdit = () => onEdit(item.inventory_item_id);
  const onDoneEdit = () => onEdit(null);
  const onClickDelete = () => onDelete(item.inventory_item_id);
  const onChangeAvailable = e => setAvailable(e.target.value.replace(/[^0-9]/g, ''));
  const onChangeReorderWarningLevel = e => setReorderWarningLevel(e.target.value.replace(/[^0-9]/g, ''));
  const onChangePrice = e => setPrice(e.target.value.replace(/[^0-9.]/g, ''));
  const onUpdateAvailable = e => onUpdate(item.inventory_item_id, 'available', available).then(
    action => {
      if (UPDATE_INVENTORY_ITEM_SUCCESS === action.type) {
        setAvailable(action.payload.inventory_item.available);
        setInitialAvailable(action.payload.inventory_item.available);
      } else if (UPDATE_INVENTORY_ITEM_FAILURE === action.type) {
        setAvailable(initialAvailable);
      }
    }
  );
  const onUpdateReorderWarningLevel = e => onUpdate(item.inventory_item_id, 'reorder_warning_level', reorderWarningLevel).then(
    action => {
      if (UPDATE_INVENTORY_ITEM_SUCCESS === action.type) {
        setReorderWarningLevel(action.payload.inventory_item.reorder_warning_level || '');
        setInitialReorderWarningLevel(action.payload.inventory_item.reorder_warning_level || '');
      } else if (UPDATE_INVENTORY_ITEM_FAILURE === action.type) {
        setReorderWarningLevel(initialReorderWarningLevel);
      }
    }
  );
  const onUpdatePrice = e => onUpdate(item.inventory_item_id, 'price', price).then(
    action => {
      if (UPDATE_INVENTORY_ITEM_SUCCESS === action.type) {
        setPrice(action.payload.inventory_item.price);
        setInitialPrice(action.payload.inventory_item.price);
      } else if (UPDATE_INVENTORY_ITEM_FAILURE === action.type) {
        setPrice(initialPrice);
      }
    }
  );
  const onUpdateWarehouseCode = e => onUpdate(item.inventory_item_id, 'warehouse_code', warehouseCode).then(
    action => {
      if (UPDATE_INVENTORY_ITEM_SUCCESS === action.type) {
        setWarehouseCode(action.payload.inventory_item.warehouse_code);
        setInitialWarehouseCode(action.payload.inventory_item.warehouse_code);
      } else if (UPDATE_INVENTORY_ITEM_FAILURE === action.type) {
        setWarehouseCode(initialWarehouseCode);
      }
    }
  );

  return (
    <TR>
      <TD>{item.item_sku} - {item.item_name}</TD>
      <TD>
	{item.warehouse_code}
      </TD>
      <TD>{item.sku_description || `${item.size_name} / ${item.color_name}`}</TD>
      <TD>{item.on_order}</TD>
      <TD>{editing ?
        <Input value={available} onChange={onChangeAvailable} onBlur={onUpdateAvailable} /> :
        available}
      </TD>
      <TD>{item.reserved}</TD>
      <TD>{editing ?
        <Input value={reorderWarningLevel} onChange={onChangeReorderWarningLevel} onBlur={onUpdateReorderWarningLevel} /> :
        reorderWarningLevel}
      </TD>
      <TD>${formatMoney(item.cost)}</TD>
      <TD>{(editing && 'DISTRIBUTOR' === inventory.owner_type) ?
        <Input value={price} onChange={onChangePrice} onBlur={onUpdatePrice} /> :
        '$' + formatMoney(price)}</TD>
      <TD>{editing ?
        <>
          <Button size="tiny" onClick={onDoneEdit}>Done</Button>
          {false && item.available == 0 && item.on_order == 0 && item.reserved == 0 && <>
            <br/>
            <Button size="tiny" onClick={onClickDelete}>Delete</Button>
          </>}
	</>:
        <Button size="tiny" onClick={onClickEdit}>Edit</Button>}
      </TD>
    </TR>
  );
};

const ProductStock = ({ inventory, inventoryItems, onEdit, onDelete, onUpdate, editing }) => (
  <Table>
    <THead>
      <TR>
        <TH>Product</TH>
	<TH>Warehouse Code</TH>
        <TH>SKU (Size / Color)</TH>
        <TH>On Order</TH>
        <TH>Available</TH>
        <TH>Reserved</TH>
        <TH>Alert</TH>
        <TH>Inventory Cost</TH>
        <TH>Inventory Price</TH>
        <TH>&nbsp;</TH>
      </TR>
    </THead>
    <TBody>
      {inventoryItems.length === 0 ?
        <TR>
          <TD colSpan={9} style={{ align: 'center' }}>No products yet</TD>
        </TR> :
        inventoryItems.map(
          ii => <ItemProductStock
            key={ii.inventory_item_id}
            inventory={inventory}
            item={ii}
            onEdit={onEdit}
            onDelete={onDelete}
            onUpdate={onUpdate}
            editing={editing === ii.inventory_item_id}
          />
        )}
    </TBody>
  </Table>
);

const isInventoryConfigured = inventory => !!inventory.inventory_name && !!inventory.warehouse && !!inventory.warehouse.warehouse_name && !!inventory.warehouse.contact_id && !!inventory.warehouse.shipping_address_id;

const InventoryProducts = ({ inventory, order_id }) => {
  const [editing, setEditing] = useState(null);
  const inventoryItems = useSelector(state => getInventoryItemsByInventoryId(state, inventory));
  const shop = useSelector(state => getShopById(state, inventory));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!inventory.inventory_id) {
      dispatch(loadInventory(inventory.inventory_id));
    }
  }, []);

  const onUpdate = (inventory_item_id, field, value) => dispatch(updateInventoryItem(inventory_item_id, field, value));
  const onDelete = (inventory_item_id) => dispatch(deleteInventoryItem(inventory_item_id));

  if (!isInventoryConfigured(inventory)) {
    return (
      <div style={{ marginTop: '1rem', marginRight: '1rem' }}>
        <p>It appears like you still need to configure your inventory.  Skip over to the <NavLink end to={`/shop/${shop.shop_number}`}>Settings</NavLink> tab to do that now.  You can come back here to add some products when that's done.</p>
      </div>
    );
  }

  return (
    <div style={{ marginTop: '1rem', marginRight: '1rem' }}>
      <ProductStock inventory={inventory} inventoryItems={inventoryItems} onEdit={setEditing} onDelete={onDelete} onUpdate={onUpdate} editing={editing} />
    </div>
  );
};

export default InventoryProducts;
