import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from "react-router-dom";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Header from './Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import Modal from '../components/Modal';
import Menu from '../components/shop/Menu';
import ShopTitle from '../components/shop/Title';
import Overlay from '../components/Overlay';
import ShopConfiguration from '../components/shop/Configuration';
import ShopProducts from '../components/shop/Products';
import ShopInventory from '../components/shop/Inventory';
import ShopStaging from '../components/shop/Staging';
import ShopOrders from '../components/shop/Orders';
import Item from '../components/item/Item';
import ProductSearchModal from '../components/ProductSearchModal';
import ProductSuggestionModal from '../components/product/ProductSuggestionModal';
import Storefront from '../components/shop/Storefront';

import { getShortOrderByNumber, getShopByNumber } from '../selectors';
import { getAddressDropdown } from '../selectors/dropdowns';

import { isAvalaraShop } from '../helpers/order';
import { Userpilot } from 'userpilot';
import { getShopType } from '../components/shop/helpers';

const PANEL_COMPONENTS = {
  'config': ShopConfiguration,
  'storefront': Storefront,
  'products': ShopProducts,
  'inventory': ShopInventory,
  'staging': ShopStaging,
  'orders': ShopOrders,
};

class ShopApp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shop: props.shop,
      order: props.order,
    };
  }

  componentDidMount() {
    const {identity} = this.props;
    Userpilot.identify(identity,{
      name: identity.user_last_name,
      email: identity.login_email,
      first_name: identity.user_first_name,
      last_name: identity.user_last_name,
      role: identity.user_role,
      has_esp: identity.has_esp,
      has_contract: !!identity.contracted_year,
      migration_due_date: identity.migration_due_date,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(_.isEqual(prevProps.location, this.props.location)) {
      console.log('RELOAD SHOP APP', this.props.location);
      Userpilot.reload();
    }

    if(!_.isEqual(prevProps.params, this.props.params)
      || !_.isEqual(prevProps.location, this.props.location)
      || !_.isEqual(prevProps.project, this.props.project)
      || !_.isEqual(prevProps.order, this.props.order)
    ) {
      this.setState({
        shop: this.props.shop,
        order: this.props.order,
      });
    }
  }

  render() {
    const { panel, order, shop, identity, modal, popups, isAvalaraShop, params, location } = this.props;

    if (modal) {
      const ShopModalComponent = (
        <ShopModal
          modal={modal}
          params={params}
          location={location}
          isAvalaraShop={isAvalaraShop}
          shop={shop}
        />
      );
      return (
        <DndProvider backend={HTML5Backend}>
          <ErrorBoundary>
            <div>
              <Modal modal={ShopModalComponent} order={order} popups={popups} project={shop} />
              <Overlay popups={popups} />
            </div>
          </ErrorBoundary>
        </DndProvider>
      );
    }

    const menu = <Menu shop={shop} identity={identity} />;

    return (
      <DndProvider backend={HTML5Backend}>
        <ErrorBoundary>
          <Header>
            <ShopTitle shop={shop} />
          </Header>
          <MainSection popups={panel !== 'storefront' ? popups : []} menu={menu}>
            <Panel shop={shop} order={order} panel={panel} popups={popups} />
          </MainSection>
          {/* render popups inside storefront panel since some popup components use providers */}
          {panel !== 'storefront' && <Overlay popups={popups} />}
        </ErrorBoundary>
      </DndProvider>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const shop = getShopByNumber(state, ownProps.params);
  const shop_shipping_addresses = getAddressDropdown(state, { parent_id: shop.shop_id }).filter(a => a.parent_type === 'SHOP' && a.address_type === 'SHIPPING');

  return {
    identity: state.identity,
    state: state,
    popups: state.display.popups,
    shop,
    isAvalaraShop: isAvalaraShop(shop, shop_shipping_addresses),
    order: getShortOrderByNumber(state, { form_number: ownProps.params.shop_number, order_type: 'SHOP' }),
  };
};

function ShopModal({ modal, params, location, isAvalaraShop, shop, ...props }) {
  switch (modal) {
    case 'search':
      return <ProductSearchModal params={params} location={location} {...props} />;
    case 'search-suggestion':
      return <ProductSuggestionModal params={params} location={location} {...props} />;
    case 'item':
      return <Item params={params} isAvalaraOrder={isAvalaraShop} showMetadataSection={getShopType(shop) === 'POPUP_SHOP'} is_shop_free={shop.is_shop_free} {...props} />;
    default:
      return null;
  }
}

function Panel({ shop, order, panel, popups }) {
  const PanelComponent = PANEL_COMPONENTS[panel];
  if (!PanelComponent) {
    return null;
  }

  return <PanelComponent
    shop={shop}
    order={order}
    shop_id={shop.shop_id}
    {...(panel === 'storefront' ? { popups } : {})}
  />;
}

export default connect(mapStateToProps)(ShopApp);
