import React, { useCallback, useEffect, useId, useState } from 'react';
import { InputDropdown, Typography } from '@commonsku/styles';
import { oauth } from '../utils';
import { uniqueOptions } from '../helpers';

type TOption = {
  value: string;
  label: string;
};

type Zip2TaxInputProps = {
  value?: string,
  label?: string,
  delayValue?: number;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  optionsListStyle?: React.CSSProperties;
  onChange?: (v: TOption) => void;
  onInputChange?: (v: string) => void;
};
type ITax = { tax_id: string, label: string, description: string, percent: string | number, };
const fetchZip2Taxes = (
  value: string
): Promise<{ json: { taxes: ITax[] } }> => oauth(
  'GET', 'tax', { zip2tax: value, zip2tax_only: 1 }
);

const Zip2TaxInput = ({ value, label, onChange, onInputChange, delayValue=800, style={}, wrapperStyle={}, optionsListStyle={} }: Zip2TaxInputProps) => {
  const testId = useId();

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [options, setOptions] = useState<TOption[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = useCallback((option: TOption) => {
    onChange?.(option);
    setOptions([]);
    setShowDropdown(false);
  }, [onChange]);

  const loadOptions = useCallback(async (input: string) => {
    let data: TOption[] = [];
    setIsLoading(true);
    setShowDropdown(true);
    try {
      const { json } = await fetchZip2Taxes(input);
      data = json.taxes.map(t => ({
        value: t.tax_id,
        label: `${t.description} (${parseFloat(t.percent + '')}%)`
      }));
    } catch (error) {
      console.log(error);
    }

    onInputChange?.(value);
    setOptions(uniqueOptions(data));
    setIsLoading(false);
  }, [onInputChange]);

  useEffect(() => {
    if (value) {
      fetchZip2Taxes(value).then(({ json }) => {
        const taxes = json.taxes || [];
        if (taxes.length === 0) {
          setInputValue(value);
          return;
        }
        const t = taxes[0];
        setInputValue(`${t.description} (${parseFloat(t.percent + '')}%)`);
      }).catch(() => {
        setInputValue(value);
      });
    } else {
      setInputValue(value);
    }
  }, [value]);

  return (
    <InputDropdown
      label={label}
      options={options}
      onChange={loadOptions}
      onSelectOption={handleChange}
      value={inputValue}
      timeout={delayValue}
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
      data-testid={'zip2tax-input-' + testId}
      placeholder='enter Zip code'
      autoComplete='false'
      extraOptions={isLoading ? (
        <Typography.Text style={{ textAlign: 'center', padding: 8 }}>
          Loading...
        </Typography.Text>
      ) : null}
      style={style}
      wrapperStyle={wrapperStyle}
      optionsListStyle={optionsListStyle}
      autoFocus={false}
    />
  );
};

export default Zip2TaxInput;
