import { useDispatch, useSelector } from "react-redux";
import { getShopById, getShopByNumber } from "../../selectors";
import { Shop } from "../../interfaces/Shop";
import { finalizeUpdateShop, updateShop } from "../../actions/shop";
import { SHOP_TYPES, getShopType } from "../../components/shop/helpers";

export const useShopById = <T extends Shop = Shop>(shop_id: string): T => {
  const result = useSelector(s => getShopById(s, { shop_id }));
  return result;
};

export const useShopByNumber = <T extends Shop = Shop>(shop_number: string | number): T => {
  const result = useSelector(s => getShopByNumber(s, { shop_number }));
  return result;
};

export const useShopTypeById = (shop_id: string) => {
  const shop = useSelector(s => getShopById(s, { shop_id }));
  return getShopType(shop);
};

export const useUpdateShopById = (shop_id: string) => {
  const dispatch = useDispatch();
  return (
    field: string,
    previous_value: any,
    value: any
  ) => dispatch(updateShop(shop_id, field, previous_value, value));
};

export const useChangeShopById = (shop_id: string) => {
  const dispatch = useDispatch();
  return (
    field: string,
    previous_value: any,
    value: any,
    response?: { shop: Shop }
  ) => dispatch(
    finalizeUpdateShop(shop_id, field, previous_value, value, response)
  );
};
