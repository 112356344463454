import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getInventoryItemsByInventoryId } from '../../selectors';

import { Button, LabeledSelect, Table, THead, TR, TH, TD, TBody } from '@commonsku/styles';
import DateInput, { CSKUInput } from '../DateInput';

import { window } from '../../global';
import { createDownload, formatMoney, oauth } from '../../utils';

const ParameterSummary = ({ inventoryItem, startDate, endDate }) => {
  if (!inventoryItem && !startDate && !endDate) {
    return <span>All Activity</span>;
  }
  return (
    <>
      <span>Activity </span>
      {!!startDate && <span>From {startDate} </span>}
      {!!endDate && <span>To {endDate} </span>}
      {!!inventoryItem ? <span>For {inventoryItem}</span> : <span>For all products</span>}
    </>
  );
};

const InventoryActivity = ({ inventory }) => {
  const orders = [];
  const [inventoryItemId, setInventoryItemId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const inventoryItems = useSelector(state => getInventoryItemsByInventoryId(state, inventory));
  const productOptions = inventoryItems.map(ii => ({
    key: ii.inventory_item_id,
    value: `${ii.warehouse_code} (${ii.item_name})`
  }));
  const itemMap = inventoryItems.reduce((o, i) => ({ ...o, [i.inventory_item_id]: i}), {});

  const [fetchedParams, setFetchedParams] = useState(null);
  const [ledger, setLedger] = useState([]);
  const fetchLedger = () => {
    const data = { inventory_id: inventory.inventory_id };
    if (!!inventoryItemId) {
      data.inventory_item_id = inventoryItemId;
    }
    if (!!startDate) {
      data.start_date = startDate;
    }
    if (!!endDate) {
      data.end_date = endDate;
    }
    oauth('GET', 'inventory-ledger', data).then(
        ({ json }) => {
          setFetchedParams({
            inventoryItem: productOptions.filter(o => o.key === inventoryItemId).map(ii => ii.value)[0],
            startDate,
            endDate
	  });
          setLedger(json.inventory_ledger);
        }
      );
  };

  const exportLedger = () => {
    const makeCSVSafe = value => {
      let finalValue = value === null ? '' : value.toString();
      finalValue = finalValue.replace(/"/g, '""');
      if (finalValue.search(/("|,|\n)/g) > 0) {
        finalValue = '"' + finalValue + '"';
      }
      return finalValue;
    };
    const content = 'data:text/csv;charset=utf-8,' + encodeURIComponent([
      ['Date', 'Item Name', 'Warehouse Code', 'Activity', 'On Order', 'Available', 'Reserved', 'Cost', 'Source', 'Source Link'].join(',')
    ].concat(ledger.map(l => [
      makeCSVSafe(l.date_created),
      makeCSVSafe(itemMap[l.inventory_item_id].item_name),
      makeCSVSafe(itemMap[l.inventory_item_id].warehouse_code),
      makeCSVSafe(l.type + (1 == l.revert ? ' (revert)' : '')),
      (l.on_order_delta > 0 ? '+' : '') + l.on_order_delta,
      (l.available_delta > 0 ? '+' : '') + l.available_delta,
      (l.reserved_delta > 0 ? '+' : '') + l.reserved_delta,
      makeCSVSafe(formatMoney(l.unit_cost)),
      makeCSVSafe(l.source_name),
      makeCSVSafe(window.origin + l.source_link)
    ].join(','))).join("\n"));
    createDownload(content, 'inventory.csv');
  };

  return (
    <div id="InventoryActivity-container" style={{ marginTop: '1rem', marginRight: '1rem' }}>
      <div style={{ display: 'block', padding: 8 }}>
        <LabeledSelect
          label="Inventory Product"
          options={productOptions}
          isClearable
          value={productOptions.filter(({ key }) => key === inventoryItemId)}
          getOptionLabel={({ value }) => value}
          getOptionValue={({ key }) => key}
          onChange={(value) => setInventoryItemId(!!value ? value.key : null)}
          containerStyles={{ marginBottom: 12 }}
        />
      </div>
      <div style={{ display: 'inline-block', width: '50%', padding: 8 }}>
        <DateInput
          customInput={<CSKUInput />}
          showMonthDropdown
          showYearDropdown
          placeholder="Start Date"
          value={startDate}
          onChange={date => setStartDate(date)}
        />
      </div>
      <div style={{ display: 'inline-block', width: '50%', paddingLeft: '0.5rem', padding: 8 }}>
        <DateInput
          customInput={<CSKUInput />}
          showMonthDropdown
          showYearDropdown
          placeholder="End Date"
          value={endDate}
          onChange={date => setEndDate(date)}
        />
      </div>
      <Button onClick={fetchLedger}>Get Activity</Button>
      {!!fetchedParams &&
        <Table>
	    <THead>
            <TR>
	        <TH colSpan={8}>
                <ParameterSummary {...fetchedParams} />
	        </TH>
              <TH>
                <Button tiny style={{ float: 'right', marginRight: '0.5rem' }} onClick={exportLedger}>Export</Button>
              </TH>
	    </TR>
	  </THead>
          <THead>
            <TR>
	        <TH>Date</TH>
              <TH>Item Name</TH>
              <TH>Warehouse Code</TH>
              <TH>Activity</TH>
              <TH>On Order</TH>
              <TH>Available</TH>
	        <TH>Reserved</TH>
              <TH>Cost</TH>
              <TH>Source</TH>
            </TR>
          </THead>
          <TBody>
            {ledger.length === 0 ?
              <TR colSpan={7}>
                <TD>No activity yet</TD>
              </TR> :
              ledger.map(
                l => <TR key={l.inventory_ledger_id}>
                  <TD>{l.date_created}</TD>
                  <TD>{itemMap[l.inventory_item_id].item_name}</TD>
                  <TD>{itemMap[l.inventory_item_id].warehouse_code}</TD>
                  <TD>{l.type}{1 == l.revert && ' (revert)'}</TD>
                  <TD>{l.on_order_delta > 0 && '+'}{l.on_order_delta}</TD>
                  <TD>{l.available_delta > 0 && '+'}{l.available_delta}</TD>
                  <TD>{l.reserved_delta > 0 && '+'}{l.reserved_delta}</TD>
                  <TD>{formatMoney(l.unit_cost)}</TD>
                  <TD><a href={l.source_link} target="_blank">{l.source_name}</a></TD>
                </TR>
              )}
          </TBody>
        </Table>}
    </div>
  );
};

export default InventoryActivity;
