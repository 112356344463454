import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateWarehouse } from '../../actions/inventory';
import { createLoadDivision, createLoadDivisionAddresses, loadAccountContacts } from '../../actions/supplier';

import { getSupplierAccountOptions, getAddressOptions, getAccountContactsOptions } from '../../selectors/dropdowns';

import { LabeledInput, LabeledSelect } from '@commonsku/styles';
import Address from '../Address';
import { Contact } from '../LabelledContact';

import { getIdentityUtils } from '../../utils';

const Warehouse = ({ warehouse }) => {
  const dispatch = useDispatch();
  const [warehouseName, setWarehouseName] = useState(warehouse.warehouse_name);
  const divisionOptions = useSelector((state) => getSupplierAccountOptions(state));
  const addressOptions = useSelector((state) => getAddressOptions(state, { parent_id: warehouse.division_id }));
  const contactOptions = useSelector((state) => getAccountContactsOptions(state, warehouse));
  const hasCapabilities = useSelector((state) => getIdentityUtils(state.identity).hasCapabilities);
  const getContact = useSelector((state) => contact_id => state.entities.contacts[contact_id]);
  const getAddress = useSelector((state) => address_id => state.entities.addresses[address_id]);

  // load the division list
  useEffect(() => {
    dispatch(createLoadDivision('supplier-account'));
  }, []);

  // load the division addresses and contacts
  useEffect(() => {
    dispatch(createLoadDivisionAddresses(warehouse.division_id));
    dispatch(loadAccountContacts(warehouse.division_id));
  }, [warehouse.division_id]);

  const address = getAddress(warehouse.shipping_address_id);
  const contact = getContact(warehouse.contact_id);

  return (
    <>
      <div className="small-12 medium-6 columns">
        <LabeledInput
          label="Warehouse"
          value={warehouseName}
	  disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={e => setWarehouseName(e.target.value)}
          onBlur={() => dispatch(updateWarehouse(warehouse.warehouse_id, 'warehouse_name', warehouseName))}
        />
        <LabeledSelect
          label="Warehouse Shipping Address"
          options={addressOptions}
          value={addressOptions.filter(({ key }) => key === warehouse.shipping_address_id)}
          getOptionLabel={({ value }) => value}
          getOptionValue={({ key }) => key}
	  disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={({ key }) => dispatch(updateWarehouse(warehouse.warehouse_id, 'shipping_address_id', key))}
          parentStyle={{ marginBottom: 9, }}
        />
        {!!address && <Address {...address} />}
      </div>
      <div className="small-12 medium-6 columns">
        <LabeledSelect
          label="Warehouse Supplier"
          options={divisionOptions}
          value={divisionOptions.filter(({ key }) => key === warehouse.division_id)}
          getOptionLabel={({ value }) => value}
          getOptionValue={({ key }) => key}
	  disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={({ key }) => dispatch(updateWarehouse(warehouse.warehouse_id, 'division_id', key))}
          parentStyle={{ marginBottom: 9, }}
        />
        <LabeledSelect
          label="Warehouse Contact"
          options={contactOptions}
          value={contactOptions.filter(({ key }) => key === warehouse.contact_id)}
          getOptionLabel={({ value }) => value}
          getOptionValue={({ key }) => key}
	  disabled={!hasCapabilities('MANAGE-INVENTORY')}
          onChange={({ key }) => dispatch(updateWarehouse(warehouse.warehouse_id, 'contact_id', key))}
          parentStyle={{ marginBottom: 9, }}
        />
        {!!contact && <Contact {...contact} />}
      </div>
    </>
  );
};
export default Warehouse;
