import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getInventoryByShopId, getShortOrderByNumber, } from '../../selectors';

import { getIdentityUtils } from '../../utils';

import { createConfirmationForDeletePopup, createCopyOrderPopup } from '../../actions/popup';
import { deleteShop } from '../../actions/shop';

import DropdownMenu, { MenuTrigger } from '../DropdownMenu';

class Title extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showFullName: false
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({ showFullName: true });
  }

  handleMouseLeave() {
    this.setState({ showFullName: false });
  }

  render() {
    const { shop, identity, inventory, order, onDeleteShop } = this.props;
    const { showFullName } = this.state;

    const { hasCapabilities } = getIdentityUtils(identity);

    const isInventoryValid = !!inventory &&
      !!inventory.inventory_name &&
      !!inventory.warehouse &&
      !!inventory.warehouse.warehouse_name &&
      !!inventory.warehouse.division_id &&
      !!inventory.warehouse.shipping_address_id &&
      !!inventory.warehouse.contact_id;

    const actionOptions = [
      {
        value: 'Copy Shop',
        hideOnClick: true,
        onClick: () => {
          const shop_type = +shop.aggregate === 1
            ? 'popup'
            : !shop.client_id
            ? 'marketing'
            : 'company';
          this.props.onCreateCopyOrderPopup(order, shop_type);
        },
        show: hasCapabilities('CREATE-SHOP'),
      },
      {
        value: <a href={`/shop/${shop.shop_id}`} target="_blank">Preview Shop</a>,
        hideOnClick: true,
        show: true
      },
      {
        value: <a href={`/send_email.php?parent_type=shop&parent_id=${shop.shop_id}`} target="_blank">Email Shop</a>,
        hideOnClick: true,
        show: true
      },
      {
        value: <a href={`/shop/${shop.shop_id}/shop?buy_inventory=true`} target="_blank">Order Inventory</a>,
        hideOnClick: true,
        show: isInventoryValid
      },
      {
        value: 'Delete Shop',
        className: 'alert',
        hideOnClick: true,
        onClick: () => onDeleteShop(),
        show: hasCapabilities('DELETE-SHOP')
      }
    ].filter(o => o.show);

    const clientUrl = `/client.php?id=${shop.client_id}`;
    const shopTitle = shop.aggregate == 1
      ? `Pop-up Shop #${shop.shop_number}`
        : shop.aggregate == 0 && !shop.client_id
          ? `Marketing Shop #${shop.shop_number}`
            : `Company Shop #${shop.shop_number}`;
    return (
      <div className="columns">
        <h4 style={{ display: 'inline-block' }}>
          {shop.client_id && <a href={clientUrl} target="_blank">{_.get(shop, ['client', 'client_name'], '')}</a>}
          {shop.client_id && <span> — </span>}
          <span>
            {shopTitle}&nbsp;
            <span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
              {shop.shop_name.length > 30 && !this.state.showFullName ? shop.shop_name.substring(0, 30) + '...' : shop.shop_name }
            </span>
          </span>
        </h4>
        <DropdownMenu style={{ float: 'right' }} className="actions-dropdown" options={actionOptions} align="right">
          <MenuTrigger>
            <a className="button actions-dropdown-button alt bold trigger-button" style={{ marginBottom: '0.5rem' }}>Actions</a>
          </MenuTrigger>
        </DropdownMenu>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  inventory: getInventoryByShopId(state, ownProps.shop),
  order: getShortOrderByNumber(state, { form_number: ownProps.shop.shop_number, order_type: 'SHOP' }),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeleteShop: () => dispatch(createConfirmationForDeletePopup(() => dispatch(deleteShop(ownProps.shop.shop_id)), [], 'shop', ownProps.shop.shop_name)),
  onCreateCopyOrderPopup: (order, shop_type=null) => dispatch(createCopyOrderPopup(order, 'SHOP', shop_type)),
});

const ConnectedTitle = connect(mapStateToProps, mapDispatchToProps)(Title);
export default ConnectedTitle;
