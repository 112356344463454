import React, { useMemo, useState } from 'react';
import { isShopFree } from '../helpers';
import AggregateTaxConfig from './AggregateTaxConfig';
import AggregateIgnoreTaxOnShippingConfig from './AggregateIgnoreTaxOnShippingConfig';
import { AlertNotification, SwitchStyled, Toggle, ToggleLink, Typography } from '@commonsku/styles';
import { useShopById, useUpdateShopById } from '../../../redux/hooks/shops';
import { getShopOptions } from '../../../helpers/shop';
import Tax from '../../../models/Tax';
import { useSelector } from 'react-redux';
import { getTaxOptions } from '../../../selectors/dropdowns';

const CalculateCheckTaxesConfig = ({ shop_id }: { shop_id: string }) => {
  const shop = useShopById(shop_id);
  const onUpdateShop = useUpdateShopById(shop_id);
  const taxes: { key: string, value: string }[] = useSelector(getTaxOptions);

  const [showWarning, setShowWarning] = useState(false);

  const shopOptions = useMemo(
    () => getShopOptions(shop?.shop_options),
    [shop?.shop_options]
  );

  const toggleCollectShippingAddress = () => {
    const current_value = +shop.calculate_checkout_taxes;
    onUpdateShop('calculate_checkout_taxes', current_value, current_value === 1 ? 0 : 1);
    onUpdateShop('default_tax_id', shop.default_tax_id, '');
    onUpdateShop('shop_options', shop.shop_options, JSON.stringify({ ...shopOptions, use_zip2tax_default: false }));
  }

  const toggleTaxCustomShippingAddress = () => {
    const current_value = +shop.tax_custom_ship_address;
    if (+shop.collect_ship_address !== 1 && current_value !== 1) {
      setShowWarning(s => !s ? true : s);
      return;
    }
    setShowWarning(s => s ? false : s);
    onUpdateShop('tax_custom_ship_address', current_value, current_value === 1 ? 0 : 1);
    const shopTax = shop.default_tax_id ? taxes.find(t => t.key === shop.default_tax_id) : null;
    if (shop.default_tax_id && (shopOptions.use_zip2tax_default ||
      (!shopOptions.use_zip2tax_default && shop.default_tax_id !== Tax.avalara_tax_id && shopTax?.value !== 'Exempt (0%)')
    )) {
      onUpdateShop('default_tax_id', shop.default_tax_id, '');
    }
  }

  const ToggleSection = (
    <div className="small-12 medium-12 columns" style={{ marginBottom: '8px' }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Typography.Text fontSize={'20px'} bold style={{ paddingRight: 24 }}>
          Calculate taxes at checkout
        </Typography.Text>
        <SwitchStyled
          size="medium"
          selected={+shop.calculate_checkout_taxes === 1}
          onClick={toggleCollectShippingAddress}
          data-testid="calculate_checkout_taxes_toggle"
        />
      </div>
      <div>
        <Typography.Text fontSize={'16px'}>
          Taxes will be calculated based on the shipping address and tax method selected below.
        </Typography.Text>
      </div>
    </div>
  );

  const Controls = (
    +shop.calculate_checkout_taxes === 1 && <>
      <div className="small-12 medium-12 columns">
        <Typography.Text fontSize={'16px'} bold style={{ marginBottom: '5px' }}>
          Tax Based On
        </Typography.Text>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <Toggle style={{ height: '32px' }}>
              <ToggleLink
                id="collect_tax_by_user"
                aria-disabled={+shop.collect_ship_address !== 1}
                selected={+shop.tax_custom_ship_address === 1}
                style={ {fontSize: '12px', lineHeight: '16px'}}
                onClick={e => {
                  e.preventDefault();
                  toggleTaxCustomShippingAddress();
                }}
              >Checkout shipping address</ToggleLink>
              <ToggleLink
                id ="collect_tax_by_shop"
                selected={+shop.tax_custom_ship_address === 0}
                style={ {fontSize: '12px', lineHeight: '16px'}}
                onClick={e => {
                  e.preventDefault();
                  toggleTaxCustomShippingAddress();
                }}
              >Client shipping address</ToggleLink>
            </Toggle>
          </div>
          {showWarning && +shop.collect_ship_address !== 1 && +shop.tax_custom_ship_address !== 1 ? <div style={{ display: 'inline-block', verticalAlign: 'middle', paddingLeft: '8px' }}>
            <AlertNotification alertType='warn' style={{ padding: '8px' }}>
              Toggle "Collect shipping address at checkout" on to use this option.
            </AlertNotification>
          </div> : null}
        </div>
      </div>
      {shop.client_id && !isShopFree(shop) ? <AggregateTaxConfig shop_id={shop_id} /> : null}
      {shop.client_id ? <AggregateIgnoreTaxOnShippingConfig shop_id={shop_id} /> : null}
    </>
  );

  return (
    <>
      {ToggleSection}
      {Controls}
      <div className="small-12 medium-12 columns" style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 0}}><hr/></div>
    </>
  );
};

export default CalculateCheckTaxesConfig;
