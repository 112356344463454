import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Select } from "@commonsku/styles";
import { ICompanyData } from "../../interfaces/CompanyData";
import Tax from "../../models/Tax";
import { getCompanyData } from "../../selectors";
import { getTaxOptions } from "../../selectors/dropdowns";

type TaxSelectProps = {
  value?: string;
  onChange?: (value: string) => void;
  shouldAlert?: boolean;
  disabled?: boolean;
  hide_manual_taxes?: boolean;
  tax_included?: boolean;
  include_zip2tax?: boolean;
  avalara?: boolean;
};

const TaxSelect = ({ value, disabled, onChange, shouldAlert=true, tax_included=true, include_zip2tax=false, avalara=false, hide_manual_taxes=false }: TaxSelectProps) => {
  const company_data: ICompanyData = useSelector(getCompanyData);
  const taxes: { key: string, value: string }[] = useSelector(getTaxOptions);

  const options = useMemo(() => {
    let base_taxes = !hide_manual_taxes ? taxes.map(t => ({ value: t.key, label: t.value })) : [];
    if (company_data?.avalara_enable && 0 == company_data?.avalara_testing && avalara) {
      base_taxes = base_taxes.filter(t => t.value === value);
    }
    if (tax_included) {
      base_taxes = [{ value: '', label: 'Tax Included In Price' }].concat(base_taxes);
    }
    if (include_zip2tax) {
      base_taxes = base_taxes.concat([{ value: 'zip2tax', label: 'Zip2Tax' }]);
    }
    if (company_data?.avalara_enable && avalara) {
      base_taxes = [{ value: Tax.avalara_tax_id, label: 'Avalara' }].concat(base_taxes.filter(t => t.value !== Tax.avalara_tax_id));
    }
    if (hide_manual_taxes) {
      const exemptTax = taxes.find(t => t.value === 'Exempt (0%)');
      base_taxes = base_taxes.concat(exemptTax ? [{ value: exemptTax.key, label: exemptTax.value }] : []);
    }
    return base_taxes;
  }, [
    taxes,
    company_data,
    value,
    tax_included,
    avalara,
    hide_manual_taxes,
  ]);

  const valueFound = useMemo(
    () => (value !== undefined && value !== null) ? options.find(v => v.value === value) : undefined,
    [value, options]
  );

  const handleChange = useCallback((e: { label: string, value: string }) => {
    if (value !== e.value && e.value === Tax.avalara_tax_id && shouldAlert) {
      let msg = '';
      if (1 == company_data?.avalara_testing) {
        msg = 'You are in Avalara testing mode, you are responsible to clean up related records';
      } else {
        msg = 'You are switching a non-avalara order to avalara, this will remove all existing tax records on this order';
      }
      if (!window.confirm(msg)) {
        return;
      }
    }
    onChange?.(e.value);
  }, [value, shouldAlert, company_data?.avalara_testing, onChange]);

  return (
    <Select
      value={valueFound}
      options={options}
      onChange={handleChange}
      isClearable={false}
      isDisabled={disabled}
    />
  );
};

export default TaxSelect;
