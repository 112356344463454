import { uniq } from 'lodash';
import React, { useMemo } from 'react';
import { LabeledCheckbox, SwitchStyled, Typography } from '@commonsku/styles';
import { countries_map } from '../../../geo';
import { useShopById, useUpdateShopById } from '../../../redux/hooks/shops';
import { getShopOptions } from '../../../helpers/shop';

const available_ship_countries = Object.keys(countries_map).map(k => ({label: countries_map[k], value: k}));

const CollectShipAddressConfig = ({ shop_id }: { shop_id: string }) => {
  const shop = useShopById(shop_id);
  const onUpdateShop = useUpdateShopById(shop_id);

  const shopOptions = useMemo(
    () => getShopOptions(shop.shop_options),
    [shop.shop_options]
  );

  const shipAddressCountries = useMemo(() => {
    const countries = shopOptions.ship_address_countries || ['us'];
    return countries;
  }, [shopOptions]);

  const onSelectAddrCountry = (country?: string) => {
    if (!country) { return; }

    let countries = shipAddressCountries;
    if (countries.includes(country)) {
      countries = countries.filter(c => c !== country);
    } else {
      countries.push(country);
    }

    // if(countries.length === 0) {
    //   countries = ['us'];
    // }

    const prev_value = shopOptions;
    onUpdateShop('shop_options', prev_value, JSON.stringify({
      ...prev_value,
      ship_address_countries: uniq(countries)
    }));
  }

  const Toggle = (<>
    <div className="small-12 medium-12 columns">
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Typography.Text fontSize={'20px'} bold style={{ paddingRight: 24 }}>
          Collect shipping address at checkout
        </Typography.Text>
        <SwitchStyled
          size="medium"
          selected={+shop.collect_ship_address === 1}
          onClick={() => {
            const prev_value = +shop.collect_ship_address;
            if (prev_value === 1 && +shop.tax_custom_ship_address === 1 && +shop.calculate_checkout_taxes === 1) {
              return;
            }
            onUpdateShop('collect_ship_address', prev_value, prev_value === 1 ? 0 : 1);
          }}
          data-testid="collect_ship_address_toggle"
        />
      </div>
    </div>
    <div className="small-12 medium-12 columns">
      <Typography.Text style={{
        ...(+shop.collect_ship_address !== 1 ? { marginBottom: 0 } : {}),
      }}>
        End customer will provide address on checkout. Select your available shipping country. Leave unchecked for all other countries
      </Typography.Text>
    </div>
  </>);

  const Controls = (
    +shop.collect_ship_address === 1 ?
      <div className="small-12 medium-12 columns">
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <div style={{ paddingRight: 16 }}>
            <Typography.Text bold>Available Countries</Typography.Text>
          </div>
          {available_ship_countries.map(c => (
            <div key={'available_ship_country-' + c.value}>
              <LabeledCheckbox
                label={c.label}
                checked={shipAddressCountries.includes(c.value)}
                onChange={(e) => onSelectAddrCountry(c.value)}
              />
            </div>
          ))}
        </div>
      </div>: null
  );

  return (
    <>
      {Toggle}
      {Controls}
      <div className="small-12 medium-12 columns" style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 0}}><hr/></div>
    </>
  );
};

export default CollectShipAddressConfig;
