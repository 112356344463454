import { useDispatch, useSelector } from "react-redux";
import { getOrderById, getOrderByNumber } from "../../selectors";
import { Order } from "../../interfaces/Order";
import updateOrder from "../../actions/order";

export const useOrderById = <T extends Order = Order>(order_id: string): T => {
  const result = useSelector(s => getOrderById(s, { order_id }));
  return result;
};

export const useOrderByNumber = <T extends Order = Order>(form_number: string | number, order_type: string): T => {
  const result = useSelector(s => getOrderByNumber(s, { form_number, order_type }));
  return result;
};

export const useUpdateOrderById = (order_id: string) => {
  const dispatch = useDispatch();
  return (
    field: string,
    previous_value: any,
    value: any
  ) => dispatch(updateOrder(order_id, field, previous_value, value));
};
