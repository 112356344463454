import React from 'react';

const ContentLoading = props => (
  <div className="main-content" style={{ textAlign: 'center' }}>
    <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
  </div>
);

export default ContentLoading;

